<template>
    <v-container fluid>
        <v-row  v-if="$store.state.user &&  $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col cols="12">
                <CreateOrganisation />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CreateOrganisation from '@/components/account_components/CreateOrganisation'
export default {
    name:'CreateOrganisationView',
    data(){
        return {}
    },
    components:{
        CreateOrganisation
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>